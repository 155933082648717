(function() {
    'use strict';
    angular
        .module('jmrsignApp')
        .factory('DocumentPageSvc', DocumentPageSvc);

    DocumentPageSvc.$inject = ['$resource', 'DateUtils', 'JmrAuth'];

    function DocumentPageSvc ($resource, DateUtils, JmrAuth) {
        var resourceUrl =  'api/accounts/:accountId/documents/:id/pages';

        return $resource(resourceUrl, {
            accountId : JmrAuth.currentAccountId,
            }, {
            // Returns the documents associated to account
            'query': { method: 'GET', isArray: true}
        });
    }
})();
