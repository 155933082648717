(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentsDeleteController',DocumentsDeleteController);

    DocumentsDeleteController.$inject = ['$uibModalInstance', 'entity', 'DocumentSvc'];

    function DocumentsDeleteController($uibModalInstance, entity, DocumentSvc) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DocumentSvc.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
