(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('PartyManagementUploadDialogController', PartyManagementUploadDialogController);

    PartyManagementUploadDialogController.$inject = ['$timeout', '$scope', '$stateParams', 
    '$uibModalInstance', 'DataUtils', 'entity', 'PartyManagement', 'JmrAuth'];

    function PartyManagementUploadDialogController ($timeout, $scope, $stateParams, 
        $uibModalInstance, DataUtils, entity, PartyManagement, JmrAuth) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.save = save;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            PartyManagement.bulkUpload(vm.document, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {

            console.log(result);
            //$scope.$emit('jmrsignApp:documentUpload', result);
            //$uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setData = function ($file, document) {

            document.accountId = JmrAuth.currentAccountId;

            if ($file) {

                var name = $file.name.split('.').shift();
                var ext = $file.name.split('.').pop().toLowerCase();

                /*if($.inArray(ext, ['csv']) == -1) {
                    swal(
                        {
                          title: "Document is not a CSV.",
                          type: "error",
                          text: "Only documents of type CSV can be uploaded.",
                          timer: 2200,
                          showConfirmButton: false
                        });

                    return;
                } */

                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        document.data = base64Data;
                        document.dataContentType = $file.type;
                        document.title = "csvfile";
                    });
                });
            }
        };
    }
})();
