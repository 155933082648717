(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('documents', {
            parent: 'app',
            url: '/documents?page&sort',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jmrsignApp.document.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/documents/documents.html',
                    controller: 'DocumentsController',
                    controllerAs: 'vm'
                }
            },         params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                searchState: {
                    value: '',
                    squash: false
                },
                searchText: {
                    value: '',
                    squash: false
                }
            },

            resolve: {
            	pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        searchState: $stateParams.searchState,
                        searchText: $stateParams.searchText
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('document');
                    $translatePartialLoader.addPart('documentStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('documents.upload', {
            parent: 'inbox',
            url: '/upload',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/documents-upload-dialog.html',
                    controller: 'DocumentsUploadDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                internalSystemRef: null,
                                externalSystemRef: null,
                                fileRef: null,
                                data: null,
                                dataContentType: null,
                                createDate: null,
                                expireDate: null,
                                title: null,
                                description: null,
                                currentVersionNo: null,
                                status: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    //$state.go('inbox', null, { reload: 'document' });
                }, function() {
                    $state.go('inbox');
                });
            }]
        }).state('documents.setup', {
            parent: 'documents',
            url: '/{id}/{wiid}/setup',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jmrsignApp.document.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/documents/documents-setup.html',
                    controller: 'DocumentsSetupController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('document');
                    $translatePartialLoader.addPart('documentStatus');
                    $translatePartialLoader.addPart('documentWorkItem');
                    $translatePartialLoader.addPart('workItemType');
                    $translatePartialLoader.addPart('workItemStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'DocumentSvc', function($stateParams, DocumentSvc) {
                    return DocumentSvc.get({id : $stateParams.id}).$promise;
                }],
                workItem: ['$stateParams', 'DocumentWorkItem', function($stateParams, DocumentWorkItem) {
                    return DocumentWorkItem.get({id : $stateParams.wiid}).$promise;
                }],
                entityPages: ['$stateParams', 'DocumentPageSvc', function($stateParams, DocumentPageSvc) {
                    return DocumentPageSvc.query({id : $stateParams.id}).$promise;
                }],
                entityWorkflow: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                    return DocumentWorkitemSvc.query({documentId : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'documents',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    return currentStateData;
                }]
            }
        })
        .state('documents.setup.edit', {
            parent: 'documents.setup',
            url: '/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/documents-edit-dialog.html',
                    controller: 'DocumentsEditDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DocumentSvc', function(DocumentSvc) {
                            return DocumentSvc.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('documents.detail', {
            parent: 'documents',
            url: '/{id}/detail',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jmrsignApp.document.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/documents/documents-detail.html',
                    controller: 'DocumentsDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('document');
                    $translatePartialLoader.addPart('documentStatus');
                    $translatePartialLoader.addPart('documentWorkItem');
                    $translatePartialLoader.addPart('workItemType');
                    $translatePartialLoader.addPart('workItemStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'DocumentSvc', function($stateParams, DocumentSvc) {
                    return DocumentSvc.get({id : $stateParams.id}).$promise;
                }],
                entityPages: ['$stateParams', 'DocumentPageSvc', function($stateParams, DocumentPageSvc) {
                    return DocumentPageSvc.query({id : $stateParams.id}).$promise;
                }],
                entityWorkflow: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                    return DocumentWorkitemSvc.query({documentId : $stateParams.id}).$promise;
                }],
                entityEvents: ['$stateParams', 'DocumentSvc', function($stateParams, DocumentSvc) {
                    return DocumentSvc.events({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'documents',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };

                    return currentStateData;
                }]
            }
        })
        .state('documents.delete', {
            parent: 'documents',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/documents-delete-dialog.html',
                    controller: 'DocumentsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Document', function(Document) {
                            return Document.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('documents', null, { reload: 'documents' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        ;
    }

})();
