(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentDialogController', DocumentDialogController);

    DocumentDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'Document', 'DocumentVersion', 'DocumentWorkItem', 'DocumentEvent', 'AppAccount'];

    function DocumentDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, Document, DocumentVersion, DocumentWorkItem, DocumentEvent, AppAccount) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.currentversions = DocumentVersion.query({filter: 'document-is-null'});
        $q.all([vm.document.$promise, vm.currentversions.$promise]).then(function() {
            if (!vm.document.currentVersionId) {
                return $q.reject();
            }
            return DocumentVersion.get({id : vm.document.currentVersionId}).$promise;
        }).then(function(currentVersion) {
            vm.currentversions.push(currentVersion);
        });
        vm.documentworkitems = DocumentWorkItem.query();
        vm.documentversions = DocumentVersion.query();
        vm.documentevents = DocumentEvent.query();
        vm.appaccounts = AppAccount.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.document.id !== null) {
                Document.update(vm.document, onSaveSuccess, onSaveError);
            } else {
                Document.save(vm.document, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jmrsignApp:documentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setPreviewImg = function ($file, document) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        document.previewImg = base64Data;
                        document.previewImgContentType = $file.type;
                    });
                });
            }
        };

        vm.setData = function ($file, document) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        document.data = base64Data;
                        document.dataContentType = $file.type;
                    });
                });
            }
        };

        vm.setDataComplete = function ($file, document) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        document.dataComplete = base64Data;
                        document.dataCompleteContentType = $file.type;
                    });
                });
            }
        };

        vm.setDataAudit = function ($file, document) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        document.dataAudit = base64Data;
                        document.dataAuditContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.expireDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
