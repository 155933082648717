(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('documents.setup.addwi', {
            parent: 'documents.setup',
            url: 'wi/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/workitems/documents-work-item-dialog.html',
                    controller: 'DocumentsWorkItemDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                seqNo: null,
                                startDate: null,
                                endDate: null,
                                email: null,
                                legalName: null,
                                accessCode: null,
                                note: null,
                                roleName: null,
                                signature: null,
                                signatureContentType: null,
                                signatureImg: null,
                                signatureImgContentType: null,
                                initialImg: null,
                                initialImgContentType: null,
                                type: null,
                                status: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('documents.setup.editwi', {
            parent: 'documents.setup',
            url: 'wi/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/workitems/documents-work-item-dialog.html',
                    controller: 'DocumentsWorkItemDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DocumentWorkItem', function(DocumentWorkItem) {
                            return DocumentWorkItem.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'document-work-item' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('documents.setup.deletewi', {
            parent: 'documents.setup',
            url: 'wi/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/documents/workitems/document-work-item-delete-dialog.html',
                    controller: 'DocumentsWorkItemDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DocumentWorkItem', function(DocumentWorkItem) {
                            return DocumentWorkItem.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: 'document-work-item' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
