(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentEventController', DocumentEventController);

    DocumentEventController.$inject = ['$scope', '$state', 'DocumentEvent'];

    function DocumentEventController ($scope, $state, DocumentEvent) {
        var vm = this;
        
        vm.documentEvents = [];

        loadAll();

        function loadAll() {
            DocumentEvent.query(function(result) {
                vm.documentEvents = result;
            });
        }
    }
})();
