(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('SignLandingController', SignLandingController);

    SignLandingController.$inject = ['$scope', '$rootScope', '$stateParams', '$state',
    'previousState', 'DataUtils', 'entity', 'workItem', 'Principal',
    'AppPartySvc', 'PartySvc','LoginService'];

    function SignLandingController($scope, $rootScope, $stateParams, $state,
        previousState, DataUtils, entity, workItem, Principal,
        AppPartySvc, PartySvc, LoginService) {
        var vm = this;

        vm.login = LoginService.open;
        vm.document = entity;
        vm.party = null;
        vm.workItem = workItem;
        vm.previousState = previousState.name;
        vm.isAuthenticated = false;
        vm.viewDocument = viewDocument;

        // Security flags
        vm.wrongUser = false;
        vm.completed = false;
        vm.invalid = false;
        vm.requireLogin = false;

        function resetFlags() {
            vm.wrongUser = false;
            vm.completed = false;
            vm.invalid = false;
            vm.requireLogin = false;
        };

        Principal.identity(true).then(checkPrincipal);

        

        function checkPrincipal() {
            vm.isAuthenticated = Principal.isAuthenticated();
            if(checkWorkitem())
            {
                if(vm.isAuthenticated)
                {
                    // set account vs user menu permissions
                    AppPartySvc.get().$promise.then(loggedInUser);
                }
                else
                {
                    anonymousUser();
                }
            }
        };

        function loggedInUser(response) {
            console.log('-- LOGGED IN USER --');
            vm.party = response;

            if(checkUser(false))
            {
                viewDocument();
            }
        };

        function checkWorkitem() {
            vm.completed = vm.workItem.status == 'COMPLETED';
            vm.invalid = vm.workItem.status != 'NOTIFIED';

            return !vm.invalid;
        };

        function checkUser()
        {
            vm.wrongUser = vm.party.id != vm.workItem.partyId;
            return !vm.wrongUser;
        }

        function checkAnonUser(){
            vm.wrongUser = vm.party.userId != null;
            vm.requireLogin = true;

            return !vm.wrongUser && vm.requireLogin;
        };

        function anonymousUser() {
            console.log('-- ANONYMOUS USER --');

            // get workitem party
            PartySvc.get({id: vm.workItem.partyId}).$promise.then(function(response) {
                    vm.party = response;
                    if(checkAnonUser())
                    {
                        viewDocument();
                    };
            });
        };

        function viewDocument(){
            $state.go('sign.info');
        };
    }
})();
