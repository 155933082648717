(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('SignViewController', SignViewController);

    SignViewController.$inject = ['$scope', '$rootScope', '$stateParams',  '$state',
    'previousState', 'DataUtils', 'entity', 'workItem', 'entityWorkflow', 'entityPages',
    'Document', 'DocumentWorkItem', 'DocumentVersion', 'AppAccount', 'SignSvc'];

    function SignViewController($scope, $rootScope, $stateParams, $state,
        previousState, DataUtils, entity, workItem, entityWorkflow, entityPages,
        Document, DocumentWorkItem, DocumentVersion, AppAccount, SignSvc) {
        var vm = this;

        vm.document = entity;
        vm.workItem = workItem;
        vm.documentWorkitems = entityWorkflow;
        vm.documentPages = entityPages;
        vm.previousState = previousState.name;
        vm.signDocument = signDocument;
        vm.rejectDocument = rejectDocument;

        $scope.totalItems = entityPages.length;
        $scope.currentPage = 1;

        SignSvc.logEvent({id : vm.workItem.id}, {
            eventGroup: 'WORKITEM',
            description: vm.workItem.legalName + ' viewed the document review page.',
            restricted: false,
            type: 'INFO'
        });

        $scope.setPage = function(pageNo) {
            $scope.currentPage = pageNo;
        };

        function signDocument(){
            swal({
                    title: "Legal Statement",
                    text: "I, " + vm.workItem.legalName + ", hereby declare that I have read and understand the contents of this document.",

                    showCancelButton: true,
                    //confirmButtonColor: "#DD6B55",
                    cancelButtonText: "No",
                    confirmButtonText: "Yes, I agree",
                    closeOnConfirm: false
                },
                function() {
                    swal.close();
                    if(vm.workItem.requireInitialPage) {
                        $state.go('sign.initial');
                    }
                    else {
                        $state.go('sign.capture');
                    }
                });
        };

        function rejectDocument(){
            swal({
              title: "Reject Document",
              text: "Please provide a reason below as to why you are declining to sign this document:",
              type: "input",
              showCancelButton: true,
              closeOnConfirm: false,
              inputPlaceholder: "Supply reason"
            },
            function(inputValue){
              if (inputValue === false) return false;

              if (inputValue === "") {
                swal.showInputError("Please provide a reason.");
                return false
              }

              SignSvc.reject({
                    id: vm.workItem.id
                }, inputValue ,
                    function() {

                        $state.go('inbox');
                        swal(
                            {
                              title: "Document",
                              type: "error",
                              text: "Has been rejected.",
                              timer: 2000,
                              showConfirmButton: false
                            });
                });
            });

        };
    }
})();
