(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentsWorkItemDeleteController',DocumentsWorkItemDeleteController);

    DocumentsWorkItemDeleteController.$inject = ['$uibModalInstance', 'entity', 'DocumentWorkItem'];

    function DocumentsWorkItemDeleteController($uibModalInstance, entity, DocumentWorkItem) {
        var vm = this;

        vm.documentWorkItem = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DocumentWorkItem.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
