(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('RootCertController', RootCertController);

    RootCertController.$inject = ['$scope','$state'];

    function RootCertController ($scope, $state) {
        var vm = this;
        
    }
})();
