(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentWorkItemController', DocumentWorkItemController);

    DocumentWorkItemController.$inject = ['$scope', '$state', 'DataUtils', 'DocumentWorkItem'];

    function DocumentWorkItemController ($scope, $state, DataUtils, DocumentWorkItem) {
        var vm = this;
        
        vm.documentWorkItems = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            DocumentWorkItem.query(function(result) {
                vm.documentWorkItems = result;
            });
        }
    }
})();
