(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentVersionPageController', DocumentVersionPageController);

    DocumentVersionPageController.$inject = ['$scope', '$state', 'DataUtils', 'DocumentVersionPage'];

    function DocumentVersionPageController ($scope, $state, DataUtils, DocumentVersionPage) {
        var vm = this;
        
        vm.documentVersionPages = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            DocumentVersionPage.query(function(result) {
                vm.documentVersionPages = result;
            });
        }
    }
})();
