(function() {
	'use strict';

	angular.module('jmrsignApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider' ];

	function stateConfig($stateProvider) {
		$stateProvider
				.state(
						'party-management',
						{
							parent : 'appadmin',
							url : '/party-management?page&sort',
							data : {
								authorities : [ 'ROLE_USER' ],
								pageTitle : 'jmrsignApp.party.home.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/app-account/party-management/party-management.html',
									controller : 'PartyManagementController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,asc',
									squash : true
								}
							},
							resolve : {
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort)
											};
										} ],
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('party');
											$translatePartialLoader
													.addPart('partyType');
											$translatePartialLoader
													.addPart('partyStatus');
											$translatePartialLoader
													.addPart('global');
											return $translate.refresh();
										} ]
							}
						})
				.state(
						'party-management.upload',
						{
							parent : 'party-management',
							url : '/upload',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/app-account/party-management/party-management-upload-dialog.html',
													controller : 'PartyManagementUploadDialogController',
													controllerAs : 'vm',
													backdrop : 'static',
													size : 'lg',
													resolve : {
														entity : function() {
															return {
																data : null,
																dataContentType : null
															};
														}
													}
												}).result.then(function() {
											$state.go('^', {}, {
												reload : false
											});
										}, function() {
											$state.go('^');
										});
									} ]
						})
				.state(
						'party-management-detail',
						{
							parent : 'appadmin',
							url : '/party-management/{id}',
							data : {
								authorities : [ 'ROLE_USER' ],
								pageTitle : 'jmrsignApp.party.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/app-account/party-management/party-management-detail.html',
									controller : 'PartyManagementDetailController',
									controllerAs : 'vm'
								}
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('party');
											$translatePartialLoader
													.addPart('partyType');
											return $translate.refresh();
										} ],
								entity : [
										'$stateParams',
										'PartyManagement',
										function($stateParams, PartyManagement) {
											return PartyManagement.get({
												id : $stateParams.id
											}).$promise;
										} ],
								previousState : [
										"$state",
										function($state) {
											var currentStateData = {
												name : $state.current.name
														|| 'party',
												params : $state.params,
												url : $state.href(
														$state.current.name,
														$state.params)
											};
											return currentStateData;
										} ]
							}
						})
				.state(
						'party-management-detail.edit',
						{
							parent : 'party-management-detail',
							url : '/detail/edit',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/app-account/party-management/party-management-dialog.html',
													controller : 'PartyManagementDialogController',
													controllerAs : 'vm',
													backdrop : 'static',
													size : 'lg',
													resolve : {
														entity : [
																'PartyManagement',
																function(
																		PartyManagement) {
																	return PartyManagement
																			.get({
																				id : $stateParams.id
																			}).$promise;
																} ]
													}
												}).result.then(function() {
											$state.go('^', {}, {
												reload : false
											});
										}, function() {
											$state.go('^');
										});
									} ]
						})
				.state(
						'party-management.new',
						{
							parent : 'party-management',
							url : '/new',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/app-account/party-management/party-management-dialog.html',
													controller : 'PartyManagementDialogController',
													controllerAs : 'vm',
													backdrop : 'static',
													size : 'lg',
													resolve : {
														entity : function() {
															return {
																internalSystemRef : null,
																email : null,
																firstName : null,
																lastName : null,
																cellNumber : null,
																newCellNumber : null,
																validationCode : null,
																signature : null,
																signatureContentType : null,
																signatureImg : null,
																signatureImgContentType : null,
																initialImg : null,
																initialImgContentType : null,
																allowUserUpdate : false,
																type : null,
																id : null
															};
														}
													}
												}).result
												.then(
														function() {
															$state
																	.go(
																			'party-management',
																			null,
																			{
																				reload : 'party-management'
																			});
														},
														function() {
															$state
																	.go('party-management');
														});
									} ]
						})
				.state(
						'party-management.edit',
						{
							parent : 'party-management',
							url : '/{id}/edit',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/app-account/party-management/party-management-dialog.html',
													controller : 'PartyManagementDialogController',
													controllerAs : 'vm',
													backdrop : 'static',
													size : 'lg',
													resolve : {
														entity : [
																'PartyManagement',
																function(
																		PartyManagement) {
																	return PartyManagement
																			.get({
																				id : $stateParams.id
																			}).$promise;
																} ]
													}
												}).result
												.then(
														function() {
															$state
																	.go(
																			'party-management',
																			null,
																			{
																				reload : 'party-management'
																			});
														}, function() {
															$state.go('^');
														});
									} ]
						})
				.state(
						'party-management.delete',
						{
							parent : 'party-management',
							url : '/{id}/delete',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/app-account/party-management/party-management-delete-dialog.html',
													controller : 'PartyManagementDeleteController',
													controllerAs : 'vm',
													size : 'md',
													resolve : {
														entity : [
																'PartyManagement',
																function(
																		PartyManagement) {
																	return PartyManagement
																			.get({
																				id : $stateParams.id
																			}).$promise;
																} ]
													}
												}).result
												.then(
														function() {
															$state
																	.go(
																			'party-management',
																			null,
																			{
																				reload : 'party-management'
																			});
														}, function() {
															$state.go('^');
														});
									} ]
						});
	}

})();
