(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentWorkItemDetailController', DocumentWorkItemDetailController);

    DocumentWorkItemDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'DocumentWorkItem', 'DocumentWorkItemAction', 'DocumentEvent', 'Party', 'Document'];

    function DocumentWorkItemDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, DocumentWorkItem, DocumentWorkItemAction, DocumentEvent, Party, Document) {
        var vm = this;

        vm.documentWorkItem = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('jmrsignApp:documentWorkItemUpdate', function(event, result) {
            vm.documentWorkItem = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
