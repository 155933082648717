(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('SignInfoController', SignInfoController);

    SignInfoController.$inject = ['$scope', '$rootScope', '$stateParams', '$state',
    'previousState', 'DataUtils', 'entity', 'workItem', 'entityWorkflow',
    'Document', 'DocumentWorkItem', 'DocumentVersion', 'AppAccount', 'SignSvc'];

    function SignInfoController($scope, $rootScope, $stateParams, $state,
        previousState, DataUtils, entity, workItem, entityWorkflow,
        Document, DocumentWorkItem, DocumentVersion, AppAccount, SignSvc) {
        var vm = this;

        vm.document = entity;
        vm.workItem = workItem;
        vm.documentWorkitems = entityWorkflow;
        vm.previousState = previousState.name;
        vm.viewDocument = viewDocument;

        SignSvc.logEvent({id : vm.workItem.id}, {
            eventGroup: 'WORKITEM',
            description: vm.workItem.legalName + ' viewed the document information page.',
            restricted: false,
            type: 'INFO'
        });

        function viewDocument(){
            $state.go('sign.view');
        };
    }
})();
