(function() {
	'use strict';
	angular.module('jmrsignApp').factory('PartySvc', PartySvc);

	PartySvc.$inject = [ '$resource', 'JmrAuth' ];

	function PartySvc($resource, JmrAuth) {
		var resourceUrl = 'api/accounts/:accountId/parties/:id';

		return $resource(resourceUrl, {	
			accountId : JmrAuth.currentAccountId
		}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET'
			},
			'save' : {
				method : 'POST'
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();
