(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentController', DocumentController);

    DocumentController.$inject = ['$scope', '$state', 'DataUtils', 'Document'];

    function DocumentController ($scope, $state, DataUtils, Document) {
        var vm = this;
        
        vm.documents = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            Document.query(function(result) {
                vm.documents = result;
            });
        }
    }
})();
