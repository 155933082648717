(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .factory('JmrAuth', JmrAuth);

    JmrAuth.$inject = ['$rootScope', '$state', '$sessionStorage', '$q', 'Principal','ProfileService', 'AppAccountSvc'];

    function JmrAuth($rootScope, $state, $sessionStorage, $q, Principal, ProfileService, AppAccountSvc) {

        var _parties = null;
        var _profile = null;

        var service = {
            hasAccount: hasAccount,
            hasCurrentAccount: hasCurrentAccount,
            hasAnyAccount: hasAnyAccount,
            currentPartyId: currentPartyId,
            currentAccountId: currentAccountId,
            canRegister: canRegister
        };

        loadAuthorities();

        //$rootScope.$watch(function() {
        //    return Principal.isAuthenticated();
        //}, function() {
        //    loadAuthorities();
        //});

        return service;

        function loadAuthorities() {
            Principal.identity().then(function(_id) {
                // TODO gets called twice
                if (_id) {
                    console.log('-- Load Authorities --');
                    _parties = _id.parties;
                } else {
                    console.log('-- Remove Authorities --');
                    _parties = null;
                }
            }, function() {
                console.log('-- Remove Authorities --');
                _parties = null;
            });

            ProfileService.getProfileInfo().then(function(response) {
                _profile = response;
            });
        };

        function hasAccount(accountId) {

            loadAuthorities();

            //if (!_parties) {
            //    loadAuthorities();
            //}

            for (var i = 0; i < _parties.length; i++) {
                if (_parties[i].accountId == accountId) {
                    return true;
                }
            }

            return false;
        }

        function hasCurrentAccount() {

            return this.hasAccount(this.currentAccountId());
        }

        function hasAnyAccount() {
            if (!_parties) {
                loadAuthorities();
            }

            return _parties && _parties.length > 0;
        }

        function currentAccountId() {

            switch(window.location.hostname)
            {
                // Prod system
                case "my.eezisign.com": return 1;break;
                case "jmr.eezisign.com": return 2;break;
                case "biznews.eezisign.com": return 3;break;
                case "lateralunison.eezisign.com": return 4;break;
                case "tshepang.eezisign.com": return 5;break;
                case "itleaders.eezisign.com": return 6;break;
                case "sass.eezisign.com": return 7;break;
                case "mra.eezisign.com": return 8;break;
                // Demo system
                case "demo.eezisign.com": return 1;break;

                case "my.eezisign.local": return 1;break;
                case "jmr.eezisign.local": return 2;break;
                case "bcx.eezisign.local": return 3;break;

                default: return 1;break;
            };
        }

        function currentPartyId() {
            var currentAccountId = this.currentAccountId();

            if (!_parties) {
                loadAuthorities();
            }

            for (var i = 0; i < _parties.length; i++) {
                if (_parties[i].accountId == currentAccountId) {
                    return _parties[i].id;
                }
            }
        }

        function canRegister () {

            return AppAccountSvc.get({id: this.currentAccountId()}).$promise.then(function(result) {

                return result.allowUserRegistration;
            }, function(){
                return false;
            });
        }


    }
})();
