(function() {
    'use strict';
    angular
        .module('jmrsignApp')
        .factory('DocumentWorkitemSvc', DocumentWorkitemSvc);

    DocumentWorkitemSvc.$inject = ['$resource', 'DateUtils', 'JmrAuth'];

    function DocumentWorkitemSvc ($resource, DateUtils, JmrAuth) {
        var resourceUrl =  'api/accounts/:accountId/documents/:documentId/workitems/:id';

        return $resource(resourceUrl, {
            accountId : JmrAuth.currentAccountId,
            }, {
            // Returns the documents associated to account
            'query': { method: 'GET', isArray: true}
        });
    }
})();
