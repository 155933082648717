(function() {
	'use strict';

	angular.module('jmrsignApp').controller('PartyManagementController',
			PartyManagementController);

	PartyManagementController.$inject = [ '$scope', '$state', 'DataUtils',
			'PartyManagement', 'pagingParams', 'paginationConstants',
			'ParseLinks', '$http' ];

	function PartyManagementController($scope, $state, DataUtils,
			PartyManagement, pagingParams, paginationConstants, ParseLinks,
			$http) {
		var vm = this;
		vm.loadAll = loadAll;
		vm.parties = [];
		vm.parties = PartyManagement.searchText();
		vm.openFile = DataUtils.openFile;
		vm.byteSize = DataUtils.byteSize;
		vm.page = 1;
		vm.totalItems = null;
		vm.searchText = '';
		vm.search = search;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.transition = transition;
		vm.loadAll();

		function onSuccess(result, headers) {
			var hiddenPartySize = 0;
			for ( var i in result) {
				if (result[i]['login']) {
					result.splice(i, 1);
					hiddenPartySize++;
				}
			}
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count') - hiddenPartySize;
			vm.queryCount = vm.totalItems;
			vm.page = pagingParams.page;
			vm.parties = result;
		}
		function loadAll() {
			PartyManagement.searchText({
				searchText : vm.searchText,
				page : pagingParams.page - 1,
				size : vm.itemsPerPage,
				sort : sort()
			}, onSuccess, onError);
		}
		
		function search() {
			vm.loadAll();
		}

		function onError(error) {
			AlertService.error(error.result.message);
		}

		function sort() {
			var result = [ vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc') ];
			if (vm.predicate !== 'id') {
				result.push('id');
			}
			return result;
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search : vm.currentSearch
			});
		}
	}
})();
