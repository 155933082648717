(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('SignCaptureController', SignCaptureController);

    SignCaptureController.$inject = ['$scope', '$rootScope', '$stateParams', '$state',
    'previousState', 'DataUtils', 'entity', 'workItem',
    'Document', 'DocumentVersion','PartySvc','SignSvc', 'Principal',
    '$timeout', 'DocumentWorkitemsSvc'  // JSS-54
    ];

    function SignCaptureController($scope, $rootScope, $stateParams, $state,
            previousState, DataUtils, entity, workItem,
            Document, DocumentVersion, PartySvc, SignSvc, Principal,
            $timeout, DocumentWorkitemsSvc
        ) {
        var vm = this;

        vm.credentials = { pin: null };
        vm.account = null;
        vm.party = null;
        vm.document = entity;
        vm.workItem = workItem;
        vm.requireOTP = vm.workItem.requireTwoStepAuth;
        vm.requireCode = vm.workItem.requireAccessCode;
        vm.previousState = previousState.name;
        vm.signDocument = signDocument;
        vm.resendOTP = resendOTP;
        vm.isAuthenticated = false;
        vm.wia = null;
        vm.date = new Date();

        // JSS-54
        $scope.workItemsResponseData = [];
        $scope.userWorkItems = [];

        SignSvc.logEvent({id : vm.workItem.id}, {
            eventGroup: 'WORKITEM',
            description: vm.workItem.legalName + ' initiated document signing process.',
            restricted: false,
            type: 'INFO'
        });

        Principal.identity(true).then(checkPrincipal);
        function checkPrincipal() {
            vm.isAuthenticated = Principal.isAuthenticated();
        };

        PartySvc.get({id : vm.workItem.partyId}).$promise.then(function(party) {
            vm.party = party;
            console.log(vm.party);
        });

        console.log(vm.workItem.id);
        SignSvc.requestOTP({id : vm.workItem.id}, false).$promise.then(function(result) {
            vm.wia = result;
        });

        function signDocument(){

            if(vm.requireOTP)
            {
                SignSvc.validateOTP({
                        id: vm.workItem.id
                    }, vm.credentials ,
                        successfulOTP,
                        failedOTP);
            }
            else
            {
                successfulOTP();
            }

        };

        function successfulOTP(result) {
            // success
            SignSvc.complete({
                    id: vm.workItem.id
                }, {} ,
                successfulSign,
                failedSign);
        };

        function successfulSign(result) {
        	if(vm.isAuthenticated)
            {
        		$state.go('inbox');
            }
        	else
        	{
        		$state.go('register');
        	}
            swal(
                {
                    title: "Document",
                    type: "success",
                    text: "Has been signed.",
                    timer: 2000,
                    showConfirmButton: false
            });
        };

        function failedSign(result) {
            swal(
                {
                    title: "Document cannot be signed.",
                    type: "error",
                    text: "Please contact your administrator.",
                    timer: 2200,
                    showConfirmButton: false
            });
        };

        function failedOTP(result) {
            // fail
            swal(
                {
                    title: "PIN is not correct.",
                    type: "error",
                    text: "Please verify you have entered the correct value.",
                    timer: 2200,
                    showConfirmButton: false
            });
        };

        function resendOTP() {
            SignSvc.requestOTP({id : vm.workItem.id}, true).$promise.then(function(result) {
                vm.wia = result;
            });
        };

        function validatePIN(data) {
            console.log('success');
            console.log(data);
        }

        // JSS-54
        getWorkItems();

        // JSS-54
        function getWorkItems() {
            DocumentWorkitemsSvc.getWorkItems(vm.document.id).then(function(response) {
                $scope.workItemsResponseData = response.data;

                for (var i = 0; i < $scope.workItemsResponseData.length; i++) {
                    if (vm.party.id === $scope.workItemsResponseData[i].partyId) {
                        $scope.userWorkItems.push($scope.workItemsResponseData[i]);
                    }
                }
            });
        }

        // JSS-54
        angular.element(document).ready(function () {
             $timeout(function() {
                 for (var i = 0; i < $scope.userWorkItems.length; i++) {
                    if ($scope.userWorkItems[i].pageNo !== null) {
                        if (vm.party.id == $scope.userWorkItems[i].partyId) {
                            $('img#document-image-'+$scope.userWorkItems[i].pageNo).selectAreas({
                                minSize: [150, 60],
                                allowEdit: false,
                                areas: [
                                    {
                                        x: $scope.userWorkItems[i].xPos,
                                        y: $scope.userWorkItems[i].yPos,
                                        width: 150,
                                        height: 60
                                    }
                                ]
                            });
                        }
                    }
                 }
             }, 100);
        });
    }
})();
