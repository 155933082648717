(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('AppAccountDeleteController',AppAccountDeleteController);

    AppAccountDeleteController.$inject = ['$uibModalInstance', 'entity', 'AppAccount'];

    function AppAccountDeleteController($uibModalInstance, entity, AppAccount) {
        var vm = this;

        vm.appAccount = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AppAccount.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
