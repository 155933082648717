(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentsSetupController', DocumentsSetupController);

    DocumentsSetupController.$inject = [
        '$scope', '$rootScope', '$stateParams', '$state',
        'previousState', 'DataUtils', 'entity', 'workItem', 'entityPages', 'entityWorkflow',
        'DocumentSvc','DocumentWorkItem', 'PartySearchService', 'PartyNewService', 'SignSvc',
        'DocumentWorkitemSvc'
    ];

    function DocumentsSetupController(
        $scope, $rootScope, $stateParams, $state,
        previousState, DataUtils, entity, workItem, entityPages, entityWorkflow,
        DocumentSvc, DocumentWorkItem, PartySearchService, PartyNewService, SignSvc,
        DocumentWorkitemSvc) {
        var vm = this;

        // PartySearchService.open();

        vm.document = entity;
        vm.workItem = workItem;
        vm.documentPages = entityPages;
        vm.documentWorkitems = entityWorkflow;
        vm.sigBox = [];
        vm.isSaving = false;
        vm.documentWorkItem = null;
        vm.saveWorkitem = saveWorkitem;

        vm.totalItems = entityPages.length;
        vm.currentPage = 1;

        vm.areas = [];
        vm.sigs = [];
        vm.isAdding = false;

        // JSS-11/47
        vm.addingSignature = false;

        for(var i = 0; i < vm.documentPages.length; i++) {
            vm.areas.push(null);
        };

        $scope.setupPage = function() {
            destroyAllAreas();
            buildAreaDisplay();
        };

        function buildPageSignatures() {
            var img = document.getElementById('size-wrapper');
            vm.sigs = [];
            $(vm.documentWorkitems)
            .filter(function (i,wi){
                return wi.pageNo == vm.currentPage
                && wi.type == 'SIGNER';
            }).each(function( index, wi ) {
                    vm.sigs.push({
                        id: wi.id,
                        title: wi.email,
                        x: (wi.xPos / 1000 * img.clientWidth),
                        y: (wi.yPos / 1000 * img.clientHeight),
                        width: 150,
                        height: 60,
                    });
            });
        };

        function buildAreaDisplay() {
            vm.isAdding = false;
            buildPageSignatures();
            buildArea(false);
        };

        function buildAreaAdd() {
            vm.isAdding = true;
            vm.sigs = [];
            buildArea(true);
        };

        function buildArea(allowSelect) {

            vm.areas[vm.currentPage-1] = $('#document-image-' + vm.currentPage).selectAreas({
                aspectRatio: 0,
                overlayOpacity:0.1,
                allowSelect: allowSelect,
                allowDelete: false,
                minSize: [150, 60],
                maxSize: [150, 60],
                onChanged: signatureChanged,
                width: 0,
                areas: vm.sigs
            });

            // JSS-11/47
            vm.addingSignature = false;
        };

        function signatureChanged(event, id, areas) {
            // JSS-11/47
            if (!vm.addingSignature) {
                if(vm.isAdding)
                {
                    PartySearchService.open(linkPartyToWorkitem, cancelWorkitem);
                    return;
                }

                console.log('signatureChanged');

                var img = document.getElementById('document-image-' + vm.currentPage);
                $(vm.documentWorkitems)
                .filter(function (i,wi){
                    return wi.pageNo == vm.currentPage
                    && wi.type == 'SIGNER';
                }).each(function( index, wi ) {
                        if(areas[index] != null)
                        {
                            wi.xPos = (areas[index].x / img.clientWidth * 1000);
                            wi.yPos = (areas[index].y / img.clientHeight * 1000);
                            saveWorkitem(wi);
                        }
                });
            } else {
                console.log('Busy adding signature');
            }
        };

        function destroyAllAreas() {
            // event.stopPropagation(); // firefox
            $.each( vm.areas, function( key, value ) {
                if(value != null)
                {
                    $(value).selectAreas('destroy');
                }
            });
        }

        vm.addSignature = function(wi) {
            // JSS-11/47
            vm.addingSignature = true;

            vm.documentWorkItem = wi;
            if(vm.documentWorkItem == null)
            {
                destroyAllAreas();
                buildAreaAdd();
            }
            else
            {
                // vm.isAdding = true;
                PartySearchService.open(linkPartyToWorkitem, cancelWorkitem);
            }

            // PartySearchService.open(linkPartyToWorkitem);


			/*
			 * $('#document-image-' + vm.currentPage).selectAreas('destroy');
			 *
			 * vm.areas[vm.currentPage-1] = $('#document-image-' +
			 * vm.currentPage).selectAreas({ aspectRatio: 0, overlayOpacity:0.2,
			 * allowSelect: true, allowDelete: false, minSize: [150, 50],
			 * maxSize: [150, 50], onChanged: vm.signatureChanged, width:'100%',
			 * areas: []
			 *
			 * });
			 */

        };

        function cancelWorkitem() {
            if(vm.isAdding)
            {
                vm.isAdding = false;
                destroyAllAreas();
                buildAreaDisplay();
            };
        };

        function linkPartyToWorkitem(party) {
            console.log('LINK');
            // Create workitem and link
            if (party !== null) {
                var img = document.getElementById('document-image-' + vm.currentPage);
                var areas = $('#document-image-' + vm.currentPage).selectAreas('areas');
                if (vm.documentWorkItem == null) {
                    vm.documentWorkItem = {
                        seqNo: vm.documentWorkitems.length + 1,
                        accessCode: null,
                        note: null,
                        roleName: null,
                        type: party.wiType,
                        status: 'PENDING',
                        xPos: (areas[0].x / img.clientWidth * 1000),
                        yPos: (areas[0].y / img.clientHeight * 1000),
                        id: null
                    };
                }
                vm.documentWorkItem.partyId = party.id;
                vm.documentWorkItem.party = party;
                vm.documentWorkItem.pageNo = vm.currentPage;
                vm.documentWorkItem.email = party.email;
                vm.documentWorkItem.accessCode = party.cellNumber;
                vm.documentWorkItem.legalName = party.firstName + ' ' + party.lastName;

                var twoStep = (party.type == "EXTERNAL_USER"
                    && (party.cellNumber != null && party.cellNumber != ''));

                vm.documentWorkItem.requireTwoStepAuth = twoStep;
                vm.documentWorkItem.requireSignedCopy = vm.workItem.partyId != party.id;

                if(vm.documentWorkItem.type == 'SIGNED_COPY')
                {
                    vm.documentWorkItem.requireSignedCopy = true;
                }
                if(vm.documentWorkItem.type == 'CARBON_COPY')
                {
                    vm.documentWorkItem.requireSignedCopy = false;
                }

                saveWorkitem(vm.documentWorkItem);
            }
            else
            {
                PartyNewService.open(linkPartyToWorkitem);
            }
        };

        function saveWorkitem(wi) {
            vm.isSaving = true;
            // Override documentid to current
            wi.documentId = vm.document.id;
            if (wi.id !== null) {
                DocumentWorkItem.update(wi, onSaveSuccess, onSaveError);
            } else {
                DocumentWorkItem.save(wi, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {

            vm.isSaving = false;
            DocumentWorkitemSvc.query({documentId : $stateParams.id}).$promise.then(function(results) {
                vm.documentWorkitems = results;

                if(vm.isAdding)
                {
                    vm.isAdding = false;
                    destroyAllAreas();
                    buildAreaDisplay();
                };
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.userSearch = function(wi) {
            var dialog = ngDialog.open({
                template: '/App/Main/modals/users/search.cshtml',
                showClose: false,
                disableAnimation: true
            });

            dialog.closePromise.then(function(data) {
                wi.user = data.value;
            });
        };

        vm.removeSignature = function(wi) {
            swal({
                    title: "Are you sure?",
                    text: "The signature will be removed from the document.",
                    type: "warning",
                    showCancelButton: true,
                    // confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, remove it",
                    closeOnConfirm: false
                },
                function() {
                    DocumentWorkItem.delete({
                            id: wi.id
                        },
                        function() {
                            swal.close();
                            toastr.success('Work item has been removed.', 'Success');
                            $state.reload();
                        });
                });
        };



        vm.updateSignatureLocation = function() {
            var sigs = sigBox.selectAreas('relativeAreas');

            for (i = 0; i < vm.document.workflow.length; i++) {
                var wi = vm.document.workflow[i];
                var sig = sigs[i];
                wi.xPos = sig.x;
                wi.yPos = sig.y;
            }
        };

        vm.finalize = function() {

            if(!validate())
            {
                return;
            };

            swal({
                    title: "Are you sure?",
                    text: "The document flow will be secured, and the document will be forwarded on to the first person to sign.",
                    type: "warning",
                    showCancelButton: true,
                    // confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, finalize document",
                    closeOnConfirm: false
                },
                function() {
                    SignSvc.complete({
                            id: vm.workItem.id
                        }, {} ,
                        function() {
                            swal.close();
                            $state.go('inbox');
                            toastr.success('Document has been locked.', 'Success');
                        });
                });
        };

        function validate() {

            var hasSig = false;
            var allAlloc = true;

            $.each(vm.documentWorkitems, function( index, wi ) {

              if(wi.type == 'SIGNER')
              {
                hasSig = true;
              }

              if(wi.partyId == null)
              {
                allAlloc = false;
              }

            });

            if(!hasSig) {
                toastr.error('At least one signatory is required.', 'Error');
            };
            if(!allAlloc) {
                toastr.error('All signatories are not allocated to a party.', 'Error');
            };

            return hasSig && allAlloc;
        };

        vm.previousState = 'inbox';
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;



        var unsubscribe = $rootScope.$on('jmrsignApp:documentUpdate', function(event, result) {
            vm.document = result;
        });

        vm.moveUp = function(wi, index) {
            if (vm.document.status == "DRAFT" || vm.document.status == "IN_PROGRESS") {
                if (wi.status != "COMPLETED" && wi.status != "NOTIFIED") {
                    if (index > 1) {  //first signer can not move up to editor's place
                        var tempWiPrev = angular.copy(vm.documentWorkitems[index - 1]);
                        var tempWiCurr = angular.copy(vm.documentWorkitems[index]);

                        tempWiPrev.seqNo = angular.copy(vm.documentWorkitems[index].seqNo);
                        tempWiCurr.seqNo = angular.copy(vm.documentWorkitems[index - 1].seqNo);
                        tempWiPrev.id = angular.copy(vm.documentWorkitems[index].id);
                        tempWiCurr.id = angular.copy(vm.documentWorkitems[index - 1].id);

                        vm.documentWorkitems[index - 1] = tempWiCurr;
                        vm.documentWorkitems[index] = tempWiPrev;

                        //save both work items with new seqNo's
                        vm.saveWorkitem(tempWiPrev);
                        vm.saveWorkitem(tempWiCurr);
                    }
                }
            }

        }

        vm.moveDown = function(wi, index) {
             if (vm.document.status == "DRAFT" || vm.document.status == "IN_PROGRESS") {
                if (wi.status != "COMPLETED" && wi.status != "NOTIFIED") {
                    if (index < vm.documentWorkitems.length - 1) {  //First item is the list is the editor
                        var tempWiCurr = angular.copy(vm.documentWorkitems[index]);
                        var tempWiNext = angular.copy(vm.documentWorkitems[index + 1]);

                         tempWiCurr.seqNo = angular.copy(vm.documentWorkitems[index + 1].seqNo);
                         tempWiNext.seqNo = angular.copy(vm.documentWorkitems[index].seqNo);
                         tempWiCurr.id = angular.copy(vm.documentWorkitems[index + 1].id);
                         tempWiNext.id = angular.copy(vm.documentWorkitems[index].id);

                         vm.documentWorkitems[index] = tempWiNext;
                         vm.documentWorkitems[index + 1] = tempWiCurr;

                         //save both work items with new seqNo's
                         vm.saveWorkitem(tempWiCurr);
                         vm.saveWorkitem(tempWiNext);
                     }
                }
             }
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
