(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('sign', {
                parent: 'app',
                url: '/sign/{id}/{wiid}',
                data: {
                    authorities: [],
                    pageTitle: 'jmrsignApp.document.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/sign/landing.html',
                        controller: 'SignLandingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('documentStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DocumentSvc', function($stateParams, DocumentSvc) {
                        return DocumentSvc.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    workItem: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                        return DocumentWorkitemSvc.get({
                            documentId: $stateParams.id,
                            id: $stateParams.wiid
                        }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'inbox',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };

                        return currentStateData;
                    }]
                }
            })
            .state('sign.info', {
                parent: 'sign',
                url: '/info',
                data: {
                    authorities: [],
                    pageTitle: 'jmrsignApp.document.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/sign/info.html',
                        controller: 'SignInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('documentStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DocumentSvc', function($stateParams, DocumentSvc) {
                        return DocumentSvc.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    workItem: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                        return DocumentWorkitemSvc.get({
                            documentId: $stateParams.id,
                            id: $stateParams.wiid
                        }).$promise;
                    }],
                    entityWorkflow: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                        return DocumentWorkitemSvc.query({
                            documentId: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'inbox',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };

                        return currentStateData;
                    }]
                }
            })
            .state('sign.view', {
                parent: 'sign',
                url: '/view',
                data: {
                    authorities: [],
                    pageTitle: 'jmrsignApp.document.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/sign/view.html',
                        controller: 'SignViewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('documentStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DocumentSvc', function($stateParams, DocumentSvc) {
                        return DocumentSvc.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    workItem: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                        return DocumentWorkitemSvc.get({
                            documentId: $stateParams.id,
                            id: $stateParams.wiid
                        }).$promise;
                    }],
                    entityPages: ['$stateParams', 'DocumentPageSvc', function($stateParams, DocumentPageSvc) {
                        return DocumentPageSvc.query({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    entityWorkflow: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                        return DocumentWorkitemSvc.query({
                            documentId: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'inbox',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };

                        return currentStateData;
                    }]
                }
            })
            .state('sign.initial', {
                parent: 'sign',
                url: '/initial',
                data: {
                    authorities: [],
                    pageTitle: 'jmrsignApp.document.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/sign/initial.html',
                        controller: 'SignInitialController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('documentStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DocumentSvc', function($stateParams, DocumentSvc) {
                        return DocumentSvc.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    workItem: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                        return DocumentWorkitemSvc.get({
                            documentId: $stateParams.id,
                            id: $stateParams.wiid
                        }).$promise;
                    }],
                    entityPages: ['$stateParams', 'DocumentPageSvc', function($stateParams, DocumentPageSvc) {
                        return DocumentPageSvc.query({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    entityWorkflow: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                        return DocumentWorkitemSvc.query({
                            documentId: $stateParams.id
                        }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'inbox',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };

                        return currentStateData;
                    }]
                }
            })
            .state('sign.capture', {
                parent: 'sign',
                url: '/sign',
                data: {
                    authorities: [],
                    pageTitle: 'jmrsignApp.document.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/sign/capture.html',
                        controller: 'SignCaptureController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('document');
                        $translatePartialLoader.addPart('documentStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'DocumentSvc', function($stateParams, DocumentSvc) {
                        return DocumentSvc.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    workItem: ['$stateParams', 'DocumentWorkitemSvc', function($stateParams, DocumentWorkitemSvc) {
                        return DocumentWorkitemSvc.get({
                            documentId: $stateParams.id,
                            id: $stateParams.wiid
                        }).$promise;
                    }],
                    previousState: ["$state", function($state) {
                        var currentStateData = {
                            name: $state.current.name || 'inbox',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };

                        return currentStateData;
                    }]
                }
            });
    }

})();
