(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('PartyController', PartyController);

    PartyController.$inject = ['$scope', '$state', 'DataUtils', 'Party'];

    function PartyController ($scope, $state, DataUtils, Party) {
        var vm = this;

        vm.parties = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            Party.query(function(result) {
                vm.parties = result;
            });
        }
    }
})();
