(function() {
	'use strict';
	angular.module('jmrsignApp').factory('PartyManagement', PartyManagement);

	PartyManagement.$inject = [ '$resource', 'JmrAuth', '$q' ];

	function PartyManagement($resource, JmrAuth, $q) {
		var resourceUrl = 'api/accounts/:accountId/parties/:id';
		console.log(resourceUrl);

		return $resource(resourceUrl, {
			accountId : JmrAuth.currentAccountId
		}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'search' : {
				method : 'GET',
				url :resourceUrl + '/search?q=:search',
				isArray : true
			},
			'searchText' : {
				method : 'GET',
				url :resourceUrl + '/searchText?keyword=:searchText',
				isArray : true
			},
			'update' : {
				method : 'PUT'
			},

		}, {
			'get' : {
				method : 'GET',
			},
			transformResponse : function(data) {
				if (data) {
					data = angular.fromJson(data);
				}
				return data;
				console.log(data);
				console.log(resourceUrl);
			}
		}, {

			'bulkUpload' : {
				url : resourceUrl + '/bulkUpload',
				method : 'POST'
			}

		});
	}
})();
