(function() {
	'use strict';

	angular.module('jmrsignApp').controller('PartySearchController',
			PartySearchController);

	PartySearchController.$inject = [ '$rootScope', '$state', '$timeout',
			'PartyManagement', 'party', '$uibModalInstance',
			'PartySearchService', '$http', '$q', '$scope' ];

	function PartySearchController($rootScope, $state, $timeout,
			PartyManagement, party, $uibModalInstance, PartySearchService,
			$http, $q, $scope) {
		var vm = this;

		// vm.parties = PartyManagement.query();
        vm.type = 'SIGNER';
		vm.party = undefined;
		vm.currentParty = party;
		vm.cancel = cancel;
		vm.select = select;
		vm.selectCurrent = selectCurrent;
		vm.newParty = newParty;
		vm.noResults = false;
		vm.getAllPartiesSearch = getAllPartiesSearch;

		$timeout(function() {
			angular.element('#field_party').focus();
		});

		function newParty() {
			$uibModalInstance.close(null);
		}

		function cancel() {
			$uibModalInstance.dismiss('cancel');
			// $uibModalInstance.close(null, true);
		}

		function select() {
            vm.party.wiType = vm.type;
			$uibModalInstance.close(vm.party);
		}

		function selectCurrent() {
		    vm.currentParty.wiType = vm.type;
			$uibModalInstance.close(vm.currentParty);
		}

		function getAllPartiesSearch(searchStr) {
			var defer = $q.defer();
			if (searchStr) {
				PartyManagement.search({
					search : searchStr
				}, function(data, headers) {
					if (!$scope.$$phase) { // check if digest is not in
						// progress
						$rootScope.$apply(function() {
							defer.resolve(data);
						});
					} else {
						defer.resolve(data);
					}
				}, function(response) {
					if (!$scope.$$phase) {
						$rootScope.$apply(function() {
							defer.reject('Server rejected with status '
									+ response.status);
						});
					} else {
						defer.reject('Server rejected with status '
								+ response.status);
					}
				});
			} else {
				if (!$scope.$$phase) {
					$rootScope.$apply(function() {
						defer.reject('No search query ');
						$log.info('No search query provided');
					});
				} else {
					defer.reject('No search query ');
					$log.info('No search query provided');
				}
			}
			return defer.promise;
		}
		;

	}
	;

})();
