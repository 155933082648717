(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentWorkItemDialogController', DocumentWorkItemDialogController);

    DocumentWorkItemDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'DocumentWorkItem', 'DocumentWorkItemAction', 'DocumentEvent', 'Party', 'Document'];

    function DocumentWorkItemDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, DocumentWorkItem, DocumentWorkItemAction, DocumentEvent, Party, Document) {
        var vm = this;

        vm.documentWorkItem = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.documentworkitemactions = DocumentWorkItemAction.query();
        vm.documentevents = DocumentEvent.query();
        vm.parties = Party.query();
        vm.documents = Document.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.documentWorkItem.id !== null) {
                DocumentWorkItem.update(vm.documentWorkItem, onSaveSuccess, onSaveError);
            } else {
                DocumentWorkItem.save(vm.documentWorkItem, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jmrsignApp:documentWorkItemUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;

        vm.setSignature = function ($file, documentWorkItem) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        documentWorkItem.signature = base64Data;
                        documentWorkItem.signatureContentType = $file.type;
                    });
                });
            }
        };

        vm.setSignatureImg = function ($file, documentWorkItem) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        documentWorkItem.signatureImg = base64Data;
                        documentWorkItem.signatureImgContentType = $file.type;
                    });
                });
            }
        };

        vm.setInitialImg = function ($file, documentWorkItem) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        documentWorkItem.initialImg = base64Data;
                        documentWorkItem.initialImgContentType = $file.type;
                    });
                });
            }
        };

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
