(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .factory('PartyNewService', PartyNewService);

    PartyNewService.$inject = ['$uibModal'];

    function PartyNewService ($uibModal) {
        var service = {
            open: open
        };

        var modalInstance = null;
        var callback = null;
        var cancelCallback = null;

        var resultModal = function (result) {
            if(callback !== null)
            {
                callback(result);
            }

            callback = null;
            cancelCallback = null;
            modalInstance = null;
        };

        var resetModal = function () {
            if(cancelCallback !== null)
            {
                cancelCallback();
            }

            modalInstance = null;
            cancelCallback = null;
            callback = null;
        };

        return service;

        function open (callbackFn, cancelFn) {
            callback = callbackFn;
            cancelCallback = cancelFn;
            if (modalInstance !== null) return;
            modalInstance = $uibModal.open({
                animation: true,
                templateUrl: 'app/components/party/party-new.html',
                controller: 'PartyNewController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('party');
                        $translatePartialLoader.addPart('partyType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function () {
                            return {
                                internalSystemRef: null,
                                email: null,
                                firstName: null,
                                lastName: null,
                                cellNumber: null,
                                newCellNumber: null,
                                validationCode: null,
                                signature: null,
                                signatureContentType: null,
                                signatureImg: null,
                                signatureImgContentType: null,
                                initialImg: null,
                                initialImgContentType: null,
                                allowUserUpdate: true,
                                status: 'RESTRICTED',
                                type: null,
                                id: null
                            };
                        }
                }
            });
            modalInstance.result.then(
                resultModal,
                resetModal
            );
        }
    }
})();
