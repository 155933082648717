(function() {
	'use strict';

	angular.module('jmrsignApp').controller('DocumentsController',
			DocumentsController);

	DocumentsController.$inject = [ '$scope', '$state', 'DataUtils',
			'DocumentSvc', 'AlertService', 'pagingParams',
			'paginationConstants', 'ParseLinks', '$http' ];

	function DocumentsController($scope, $state, DataUtils, DocumentSvc,
			AlertService, pagingParams, paginationConstants, ParseLinks, $http) {
		var vm = this;
		vm.documents = DocumentSvc.filterSearch();
		vm.loadAll = loadAll;
		vm.page = 1;
		vm.searchState = pagingParams.searchState;
		vm.searchText = pagingParams.searchText;
		vm.totalItems = null;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.transition = transition;
		vm.search = search;
		vm.documents = [];
		vm.openFile = DataUtils.openFile;
		vm.byteSize = DataUtils.byteSize;
		vm.loadAll();
		console.log(DocumentSvc.query());

		function loadAll() {
			DocumentSvc.filterSearch({
				filterSearch : (vm.searchState + ((!(vm.searchState == null || vm.searchState === '')
                                && !(vm.searchText == null || vm.searchText === '')) ? '|' : '')
                                + vm.searchText),
				page : pagingParams.page - 1,
				size : vm.itemsPerPage,
				sort : sort()
			}, onSuccess, onError);
		}

		function search() {
			vm.loadAll();
		}

		//console.log(vm.loadAll());

		function onSuccess(result, headers) {
			var hiddenDocumentSize = 0;
			for ( var i in result) {
				if (result[i]['login']) {
					result.splice(i, 1);
					hiddenDocumentSize++;
				}
			}
			vm.links = ParseLinks.parse(headers('link'));
			vm.totalItems = headers('X-Total-Count') - hiddenDocumentSize;
			vm.queryCount = vm.totalItems;
			vm.page = pagingParams.page;
			vm.documents = result;
		}
		// console.log(vm.documents);

		function onError(error) {
			AlertService.error(error.result.message);
		}

		function sort() {
			var result = [ vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc') ];
			if (vm.predicate !== 'id') {
				result.push('id');
			}
			return result;
			// console.log(result);
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page : vm.page,
				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				searchState : vm.searchState,
				searchText : vm.searchText
			});
		}

	}
})();
