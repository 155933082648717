(function() {
	'use strict';

	angular.module('jmrsignApp').controller('DocumentsDetailController',
			DocumentsDetailController);

	DocumentsDetailController.$inject = [ '$scope', '$http', '$rootScope',
			'$stateParams', '$state', 'previousState', 'DataUtils', 'entity',
			'entityPages', 'entityWorkflow', 'entityEvents', 'Document',
			'DocumentVersion', 'DocumentWorkItem', 'DocumentEvent',
			'AppAccount', 'SignSvc', 'DocumentWorkitemSvc', 'DocumentSvc'];

	function DocumentsDetailController($scope, $http, $rootScope, $stateParams,
			$state, previousState, DataUtils, entity, entityPages,
			entityWorkflow, entityEvents, Document, DocumentVersion,
			DocumentWorkItem, DocumentEvent, AppAccount, SignSvc,
			DocumentWorkitemSvc, DocumentSvc) {
		var vm = this;

		vm.document = entity;
		vm.documentPages = entityPages;
		vm.documentEvents = entityEvents;
		vm.documentWorkitems = entityWorkflow;
		vm.previousState = previousState.name;
		vm.saveWorkitem = saveWorkitem;

		console.log(vm.documentEvents);

		angular.forEach(vm.documentWorkitems, function(value, key) {
			if (value.type === 'EDITOR' && value.seqNo === 1) {
				console.log(key + ':' + value.type)
			} else if (value.type === 'SIGNER' && value.seqNo !== 1) {
				console.log(key + ':' + value.type)
			}
		});

		vm.remindDocument = function(wi) {
			swal({
				title : "Are you sure?",
				text : " A reminder notification email will be sent to" + " "
						+ wi.legalName + " " + "to sign.",
				type : "warning",
				showCancelButton : true,
				confirmButtonColor : "#DD6B55",
				confirmButtonText : "Yes, remind!",
				closeOnConfirm : false

			}, function() {
				SignSvc.remind({
					id : wi.id
				}, {}, function() {
					swal.close();
					toastr.success('Reminder email has been sent.', 'Success');
				});
			});
		}

		vm.cancelDocument = function(wi) {
			swal({
				title : "Are you sure?",
				text : "Do you want to canel" + " " + vm.document.title + " "
						+ "to sign.",
				type : "warning",
				showCancelButton : true,
				confirmButtonColor : "#DD6B55",
				confirmButtonText : "Yes, cancel!",
				closeOnConfirm : false

			}, function() {
				DocumentSvc.cancel({
					id : vm.document.id
				}, {}, function() {
					swal.close();
					toastr.success('Document cancelled.', 'Success');
					$state.go('documents.setup', {
						id : vm.document.id,
						wiid : vm.documentWorkitems[0].id
					});
				});
			});
		}

		function saveWorkitem(wi) {
            vm.isSaving = true;
            // Override documentid to current
            wi.documentId = vm.document.id;
            if (wi.id !== null) {
                DocumentWorkItem.update(wi, onSaveSuccess, onSaveError);
            } else {
                DocumentWorkItem.save(wi, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {

            vm.isSaving = false;
            DocumentWorkitemSvc.query({documentId : $stateParams.id}).$promise.then(function(results) {
                vm.documentWorkitems = results;

                if(vm.isAdding)
                {
                    vm.isAdding = false;
                    destroyAllAreas();
                    buildAreaDisplay();
                };
            });
        }

        function onSaveError() {
            vm.isSaving = false;
        }

		vm.moveUp = function(wi, index) {
            if (vm.document.status == "DRAFT" || vm.document.status == "IN_PROGRESS") {
                if (wi.status != "COMPLETED" && wi.status != "NOTIFIED") {
                    if (index > 1) {  //first signer can not move up to editor's place
                        var tempWiPrev = angular.copy(vm.documentWorkitems[index - 1]);
                        var tempWiCurr = angular.copy(vm.documentWorkitems[index]);

                        tempWiPrev.seqNo = angular.copy(vm.documentWorkitems[index].seqNo);
                        tempWiCurr.seqNo = angular.copy(vm.documentWorkitems[index - 1].seqNo);
                        tempWiPrev.id = angular.copy(vm.documentWorkitems[index].id);
                        tempWiCurr.id = angular.copy(vm.documentWorkitems[index - 1].id);

                        vm.documentWorkitems[index - 1] = tempWiCurr;
                        vm.documentWorkitems[index] = tempWiPrev;

                        //save both work items with new seqNo's
                        vm.saveWorkitem(tempWiPrev);
                        vm.saveWorkitem(tempWiCurr);
                    }
                }
            }

        }

        vm.moveDown = function(wi, index) {
             if (vm.document.status == "DRAFT" || vm.document.status == "IN_PROGRESS") {
                if (wi.status != "COMPLETED" && wi.status != "NOTIFIED") {
                    if (index < vm.documentWorkitems.length - 1) {  //First item is the list is the editor
                        var tempWiCurr = angular.copy(vm.documentWorkitems[index]);
                        var tempWiNext = angular.copy(vm.documentWorkitems[index + 1]);

                         tempWiCurr.seqNo = angular.copy(vm.documentWorkitems[index + 1].seqNo);
                         tempWiNext.seqNo = angular.copy(vm.documentWorkitems[index].seqNo);
                         tempWiCurr.id = angular.copy(vm.documentWorkitems[index + 1].id);
                         tempWiNext.id = angular.copy(vm.documentWorkitems[index].id);

                         vm.documentWorkitems[index] = tempWiNext;
                         vm.documentWorkitems[index + 1] = tempWiCurr;

                         //save both work items with new seqNo's
                         vm.saveWorkitem(tempWiCurr);
                         vm.saveWorkitem(tempWiNext);
                    }
                }
             }
        }
	}
})();
