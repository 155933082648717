(function() {
    'use strict';
    angular
        .module('jmrsignApp')
        .factory('SignSvc', SignSvc);

    SignSvc.$inject = ['$resource', 'DateUtils', 'JmrAuth'];

    function SignSvc ($resource, DateUtils, JmrAuth) {
        var resourceUrl =  'api/accounts/:accountId/sign/:id';

        return $resource(resourceUrl, {
            accountId : JmrAuth.currentAccountId,
            }, {
            // Returns the documents associated to account
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        //TODO fix
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.expireDate = DateUtils.convertDateTimeFromServer(data.expireDate);
                    }
                    return data;
                }
            },
            'complete': {
                method:'POST',
                url: resourceUrl + '/completeAction'
            },
            'remind': {
                method:'POST',
                url: resourceUrl + '/remindAction'
            },
            'reject': {
                method:'POST',
                url: resourceUrl + '/rejectAction'
            },
            'requestOTP': {
                method:'POST',
                url: resourceUrl + '/requestOTP'
            },
            'validateOTP': {
                method:'POST',
                url: resourceUrl + '/validateOTP'
            },
            'logEvent': {
                method:'POST',
                url: resourceUrl + '/logEvent'
            }
        });
    }
})();
