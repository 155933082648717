(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentWorkItemActionController', DocumentWorkItemActionController);

    DocumentWorkItemActionController.$inject = ['$scope', '$state', 'DataUtils', 'DocumentWorkItemAction'];

    function DocumentWorkItemActionController ($scope, $state, DataUtils, DocumentWorkItemAction) {
        var vm = this;
        
        vm.documentWorkItemActions = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            DocumentWorkItemAction.query(function(result) {
                vm.documentWorkItemActions = result;
            });
        }
    }
})();
