(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentsUploadDialogController', DocumentsUploadDialogController);

    DocumentsUploadDialogController.$inject = ['$timeout', '$scope', '$stateParams',
    '$uibModalInstance', 'DataUtils', 'entity', 'DocumentSvc',
    'DocumentWorkItem', 'DocumentVersion', 'AppAccount', 'JmrAuth'];

    function DocumentsUploadDialogController ($timeout, $scope, $stateParams,
        $uibModalInstance, DataUtils, entity, DocumentSvc, DocumentWorkItem,
        DocumentVersion, AppAccount, JmrAuth) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.type ='fu';
        vm.appaccounts = AppAccount.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            //TODO
            if(vm.type == 'fu')
            {
                DocumentSvc.upload(vm.document, onSaveSuccess, onSaveError);
            }
            else
            {
                DocumentSvc.download(vm.document, onSaveSuccess, onSaveError);
            }


            //onSaveSuccess(vm.document);
        }

        function onSaveSuccess (result) {

            console.log(result);
            $scope.$emit('jmrsignApp:documentUpload', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setData = function ($file, document) {

            document.accountId = JmrAuth.currentAccountId;

            if ($file) {

                var name = $file.name.split('.').shift();
                var ext = $file.name.split('.').pop().toLowerCase();

                if($.inArray(ext, ['pdf','doc','docx','xls','xlsx']) == -1) {
                    swal(
                        {
                          title: "Document is not a valid Office Document.",
                          type: "error",
                          text: "Only valid documents types can be uploaded.",
                          timer: 2200,
                          showConfirmButton: false
                        });

                    return;
                }

                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        document.data = base64Data;
                        document.dataContentType = $file.type;
                        document.title = name;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.expireDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
