(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('PartyDialogController', PartyDialogController);

    PartyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Party', 'DocumentWorkItem', 'User', 'AppAccount','Principal', 'JmrAuth'];

    function PartyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Party, DocumentWorkItem, User, AppAccount , Principal, JmrAuth) {
        var vm = this;

        vm.party = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.documentworkitems = DocumentWorkItem.query();
        vm.users = User.query();
        vm.appaccounts = AppAccount.query();
        vm.uncroppedSignatureImg = '';
        vm.uncroppedInitialImg = '';

        Principal.identity().then(function(account) {
            vm.party.email = account.email;
            vm.party.firstName = account.firstName;
            vm.party.lastName = account.lastName;
            vm.party.accountId = JmrAuth.currentAccountId();
        });

        var iti = null;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();

            var input = document.getElementById('field_cellNumber');
            iti = window.intlTelInput(input, {
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.3/js/utils.js",
                initialCountry: "za",
                preferredCountries: [ "za", "gb" ],
                separateDialCode: true
            });

        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if (iti){
                vm.party.cellNumber = iti.getNumber();
            }

            vm.isSaving = true;
            if(vm.party.signatureImg){
                var countSI = (vm.party.signatureImg.match(/base64,/g) || []).length;
                if (countSI > 0){
                    vm.party.signatureImg = vm.party.signatureImg.substr(vm.party.signatureImg.indexOf('base64,') + 'base64,'.length);
                }   
            }

            if(vm.party.initialImg){
                var countII = (vm.party.initialImg.match(/base64,/g) || []).length;
                if (countII > 0){
                    vm.party.initialImg = vm.party.initialImg.substr(vm.party.initialImg.indexOf('base64,') + 'base64,'.length);
                }   
            }
            
            if (vm.party.id !== null) {
                Party.update(vm.party, onSaveSuccess, onSaveError);
            } else {
                Party.save(vm.party, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jmrsignApp:partyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setSignatureImg = function ($file, party) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (evt) {
                    $scope.$apply(function($scope){
                        vm.uncroppedSignatureImg = evt.target.result;
                        party.signatureImgContentType = $file.type;
                    });
                };
            }
        };

        vm.setInitialImg = function ($file, party) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (evt) {
                    $scope.$apply(function($scope){
                        vm.uncroppedInitialImg = evt.target.result;
                        party.initialImgContentType = $file.type;
                    });
                };
            }
        };

    }
})();
