(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentEventDialogController', DocumentEventDialogController);

    DocumentEventDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DocumentEvent', 'Document', 'DocumentWorkItem', 'Party'];

    function DocumentEventDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DocumentEvent, Document, DocumentWorkItem, Party) {
        var vm = this;

        vm.documentEvent = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.documents = Document.query();
        vm.documentworkitems = DocumentWorkItem.query();
        vm.parties = Party.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.documentEvent.id !== null) {
                DocumentEvent.update(vm.documentEvent, onSaveSuccess, onSaveError);
            } else {
                DocumentEvent.save(vm.documentEvent, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jmrsignApp:documentEventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.eventTimestamp = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
