(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', '$scope', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'AppPartySvc', 'JmrAuth'];

    function NavbarController ($state, $scope, Auth, Principal, ProfileService, LoginService, AppPartySvc, JmrAuth) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.title = "eezi-Sign";
        vm.party = null;
        vm.roleRank = 0;
        vm.disabled = false;
        vm.info = null;
        vm.accountId = JmrAuth.currentAccountId();


        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
            vm.info = response;
        });

        $scope.$watch(vm.isAuthenticated, function() {
            if(vm.isAuthenticated)
            {
                // set account vs user menu permissions
                AppPartySvc.get().$promise.then(function(response) {
                    vm.party = response;
                    vm.roleRank = rankRole(vm.party.type, vm.party.status);
                    vm.disabled = vm.party.status == 'DISABLED';
                });
            };
        });

        vm.login = login;
        vm.logout = logout;
        vm.minRole = minRole;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.showNav = showNav;
        vm.$state = $state;

        function minRole(type){
            return vm.roleRank >= rankRole(type);
        };

        function rankRole(type, status){

            if(status != null &&
                (status == 'RESTRICTED' ||
                status == 'DISABLED'))
            {
                return 0;
            }

            switch(type) {
                case "EXTERNAL_USER":
                    return 10;
                    break;
                case "INTERNAL_USER":
                    return 20;
                    break;
                case "ACCOUNT_USER":
                    return 30;
                    break;
                case "ADMIN":
                    return 99;
                    break;
                default:
                    return 0;
            }
        };

        function showNav() {
            return vm.$state.current.name != 'documents.sign';
        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }
    }
})();
