(function() {
	'use strict';

	angular.module('jmrsignApp').controller('PartyManagementDialogController',
			PartyManagementDialogController);

	PartyManagementDialogController.$inject = [ '$timeout', '$scope',
			'$stateParams', '$uibModalInstance', 'DataUtils', 'entity',
			'PartyManagement', 'DocumentWorkItem', 'DocumentEvent', 'User',
			'AppAccount', 'Party' ];

	function PartyManagementDialogController($timeout, $scope, $stateParams,
			$uibModalInstance, DataUtils, entity, PartyManagement,
			DocumentWorkItem, DocumentEvent, User, AppAccount, Party) {
		var vm = this;

		vm.party = entity;
		vm.clear = clear;
		vm.byteSize = DataUtils.byteSize;
		vm.openFile = DataUtils.openFile;
		vm.save = save;
		vm.documentworkitems = DocumentWorkItem.query();
		vm.documentevents = DocumentEvent.query();
		vm.users = User.query();
		vm.appaccounts = AppAccount.query();

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			console.log
			vm.isSaving = true;
			if (vm.party.id !== null) {
				PartyManagement.update(vm.party, onSaveSuccess, onSaveError);
			} else {
				PartyManagement.save(vm.party, onSaveSuccess, onSaveError);
			}
			console.log(vm.party.id);
		}

		function onSaveSuccess(result) {
			$scope.$emit('jmrsignApp:partyUpdate', result);
			$uibModalInstance.close(result);
			vm.isSaving = false;
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		vm.setSignature = function($file, party) {
			if ($file) {
				DataUtils.toBase64($file, function(base64Data) {
					$scope.$apply(function() {
						party.signature = base64Data;
						party.signatureContentType = $file.type;
					});
				});
			}
		};

		vm.setSignatureImg = function($file, party) {
			if ($file && $file.$error === 'pattern') {
				return;
			}
			if ($file) {
				DataUtils.toBase64($file, function(base64Data) {
					$scope.$apply(function() {
						party.signatureImg = base64Data;
						party.signatureImgContentType = $file.type;
					});
				});
			}
		};

		vm.setInitialImg = function($file, party) {
			if ($file && $file.$error === 'pattern') {
				return;
			}
			if ($file) {
				DataUtils.toBase64($file, function(base64Data) {
					$scope.$apply(function() {
						party.initialImg = base64Data;
						party.initialImgContentType = $file.type;
					});
				});
			}
		};

	}
})();
