(function() {
    'use strict';
    angular
        .module('jmrsignApp')
        .factory('AppPartySvc', AppPartySvc);

    AppPartySvc.$inject = ['$resource', 'JmrAuth'];

    function AppPartySvc ($resource, JmrAuth) {
        var resourceUrl =  'api/accounts/:accountId/party';

        return $resource(resourceUrl, {
            accountId : JmrAuth.currentAccountId
        }, {
            'get': { method: 'GET'}
        });
    }
})();
