(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('PartyNewController', PartyNewController);

    PartyNewController.$inject = ['$rootScope', '$state', '$timeout', 'entity', 'PartyManagement', '$uibModalInstance'];

    function PartyNewController ($rootScope, $state, $timeout, entity, PartyManagement, $uibModalInstance) {
        var vm = this;
        vm.party = entity;
        vm.cancel = cancel;
        vm.save = save;
        vm.newParty = newParty;
        vm.noResults = false;


        var iti = null;
        $timeout(function (){
        angular.element('#field_email').focus();

         var input = document.getElementById('field_cellNumber');
            iti = window.intlTelInput(input, {
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/14.0.3/js/utils.js",
                initialCountry: "za",
                preferredCountries: [ "za", "gb" ],
                separateDialCode: true
            });
        });

        function newParty () {
        }

        function cancel () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {

         if (iti){
                vm.party.cellNumber = iti.getNumber();
            }
            vm.isSaving = true;
            if (vm.party.id !== null) {
                PartyManagement.update(vm.party, onSaveSuccess, onSaveError);
            } else {
                PartyManagement.save(vm.party, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            console.log('Create Result');
            console.log(result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

    }
})();
