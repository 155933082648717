(function () {
    'use strict';

    angular
        .module('jmrsignApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig ($stateProvider) {
        $stateProvider.state('appadmin', {
            abstract: true,
            parent: 'app'
        })
        .state('app-account-profile', {
            parent: 'appadmin',
            url: '/profile',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jmrsignApp.appAccount.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/app-account/profile.html',
                    controller: 'AppAccountProfileController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('appAccount');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppAccount','JmrAuth', function($stateParams, AppAccount, JmrAuth) {
                    return AppAccount.get({id : JmrAuth.currentAccountId()}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'app-account',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }
})();
