(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentsEditDialogController', DocumentsEditDialogController);

    DocumentsEditDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DataUtils', 'entity', 'Document', 'DocumentVersion', 'DocumentWorkItem', 'AppAccount'];

    function DocumentsEditDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $q, DataUtils, entity, Document, DocumentVersion, DocumentWorkItem, AppAccount) {
        var vm = this;

        vm.document = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.document.barcoded = true;
            
            if (vm.document.id !== null) {
                Document.update(vm.document, onSaveSuccess, onSaveError);
            } else {
                Document.save(vm.document, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jmrsignApp:documentUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setPreviewImg = function ($file, document) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        document.previewImg = base64Data;
                        document.previewImgContentType = $file.type;
                    });
                });
            }
        };

        vm.setData = function ($file, document) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        document.data = base64Data;
                        document.dataContentType = $file.type;
                    });
                });
            }
        };
        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.expireDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
