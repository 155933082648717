(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentVersionDialogController', DocumentVersionDialogController);

    DocumentVersionDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'DocumentVersion', 'DocumentVersionPage', 'Document'];

    function DocumentVersionDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, DocumentVersion, DocumentVersionPage, Document) {
        var vm = this;

        vm.documentVersion = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.documentversionpages = DocumentVersionPage.query();
        vm.documents = Document.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.documentVersion.id !== null) {
                DocumentVersion.update(vm.documentVersion, onSaveSuccess, onSaveError);
            } else {
                DocumentVersion.save(vm.documentVersion, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('jmrsignApp:documentVersionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setData = function ($file, documentVersion) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        documentVersion.data = base64Data;
                        documentVersion.dataContentType = $file.type;
                    });
                });
            }
        };

    }
})();
