(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('SignInitialController', SignInitialController);

    SignInitialController.$inject = ['$scope', '$rootScope', '$stateParams',  '$state', '$window',
    'previousState', 'DataUtils', 'entity', 'workItem', 'entityWorkflow', 'entityPages',
    'Document', 'DocumentWorkItem', 'DocumentVersion', 'AppAccount', 'SignSvc'];

    function SignInitialController($scope, $rootScope, $stateParams, $state, $window,
        previousState, DataUtils, entity, workItem, entityWorkflow, entityPages,
        Document, DocumentWorkItem, DocumentVersion, AppAccount, SignSvc) {
        var vm = this;

        vm.document = entity;
        vm.workItem = workItem;
        vm.documentWorkitems = entityWorkflow;
        vm.documentPages = entityPages;
        vm.previousState = previousState.name;
        vm.signDocument = signDocument;
        vm.rejectDocument = rejectDocument;

        vm.totalItems = entityPages.length;
        vm.currentPage = 1;

        SignSvc.logEvent({id : vm.workItem.id}, {
            eventGroup: 'WORKITEM',
            description: vm.workItem.legalName + ' has started the process of initialling document page.',
            restricted: false,
            type: 'INFO'
        });

        $scope.setPage = function(pageNo) {

            SignSvc.logEvent({id : vm.workItem.id}, {
                eventGroup: 'WORKITEM',
                description: vm.workItem.legalName + ' declared they have read and understood the contents of page ' + vm.currentPage + ' of this document.',
                restricted: false,
                type: 'SUCCESS'
            });

            if(vm.currentPage < vm.totalItems)
            {
                vm.currentPage = pageNo;
            }
            else {
                $state.go('sign.capture');
            }

        };

        function signDocument(){
            swal({
                    title: "Legal Statement",
                    text: "I, " + vm.workItem.legalName + ", hereby declare that I have read and understand the contents of page " + vm.currentPage + " of this document.",

                    showCancelButton: true,
                    //confirmButtonColor: "#DD6B55",
                    cancelButtonText: "No",
                    confirmButtonText: "Yes, I agree",
                    closeOnConfirm: false
                },
                function() {

                    swal.close();
                    $scope.setPage(vm.currentPage+1);
                    $window.scrollTo(0, 0);

                });
        };

        function rejectDocument(){
            swal({
              title: "Reject Document",
              text: "Please provide a reason below as to why you are declining to sign this document:",
              type: "input",
              showCancelButton: true,
              closeOnConfirm: false,
              inputPlaceholder: "Supply reason"
            },
            function(inputValue){
              if (inputValue === false) return false;

              if (inputValue === "") {
                swal.showInputError("Please provide a reason.");
                return false
              }

              SignSvc.reject({
                    id: vm.workItem.id
                }, inputValue ,
                    function() {

                        $state.go('inbox');
                        swal(
                            {
                              title: "Document",
                              type: "error",
                              text: "Has been rejected.",
                              timer: 2000,
                              showConfirmButton: false
                            });
                });
            });

        };
    }
})();
