(function() {
	'use strict';
	angular.module('jmrsignApp').factory('DocumentSvc', DocumentSvc);

	DocumentSvc.$inject = [ '$resource', 'DateUtils', 'JmrAuth'];

	function DocumentSvc($resource, DateUtils, JmrAuth) {
		var resourceUrl = 'api/accounts/:accountId/documents/:id';
		
		return $resource(resourceUrl, {
			accountId : JmrAuth.currentAccountId,
		}, {
			// Returns the documents associated to account
			'query' : {
				method : 'GET',
				isArray : true
			},
			'filterSearch' : {
				url : resourceUrl + '/filterSearch?keyword=:filterSearch',
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
						data.createDate = DateUtils
								.convertDateTimeFromServer(data.createDate);
						data.expireDate = DateUtils
								.convertDateTimeFromServer(data.expireDate);
					}
					return data;
					console.log(data);
				}
			},
			'upload' : {
				method : 'POST'
			},
			'download' : {
				url : resourceUrl + '/fromURL',
				method : 'POST'
			},
			'delete' : {
				method : 'DELETE'
			},
			'lock' : {
				url : resourceUrl + '/lock',
				method : 'POST'
			},
			'events' : {
				url : resourceUrl + '/events',
				method : 'GET',
				isArray : true
			},
			
			'cancel' : {
				url : resourceUrl + '/cancel',
				method : 'PUT'
			},
		});
	}
})();
