(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .directive('canRegister', canRegister);

    canRegister.$inject = ['JmrAuth'];

    function canRegister(JmrAuth) {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
            var flag = (attrs.canRegister == "true");
            console.log(flag);

            var setVisible = function () {
                    element.removeClass('hidden');
                },
                setHidden = function () {
                    element.addClass('hidden');
                },
                defineVisibility = function (reset) {

                    if (reset) {
                        setVisible();
                    }

                    JmrAuth.canRegister()
                        .then(function (result) {
                            console.log(result);
                            if (result && flag) {
                                setVisible();
                            } else {
                                setHidden();
                            }
                        });
                };

            defineVisibility(true);

                scope.$watch(function() {
                    return JmrAuth.currentAccountId();
                }, function() {
                    defineVisibility(true);
                });
        }
    }
})();
