(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('DocumentVersionController', DocumentVersionController);

    DocumentVersionController.$inject = ['$scope', '$state', 'DataUtils', 'DocumentVersion'];

    function DocumentVersionController ($scope, $state, DataUtils, DocumentVersion) {
        var vm = this;
        
        vm.documentVersions = [];
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;

        loadAll();

        function loadAll() {
            DocumentVersion.query(function(result) {
                vm.documentVersions = result;
            });
        }
    }
})();
