(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('account.party', {
            parent: 'account',
            url: '/party',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'jmrsignApp.party.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/party/parties.html',
                    controller: 'PartyController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('party');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('account.party-detail', {
            parent: 'account',
            url: '/party/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'jmrsignApp.party.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/party/party-detail.html',
                    controller: 'PartyDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('party');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Party', function($stateParams, Party) {
                    return Party.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'party',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('account.party-detail.edit', {
            parent: 'account.party-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/party/party-dialog.html',
                    controller: 'PartyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Party', function(Party) {
                            return Party.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('account.party.new', {
            parent: 'account.party',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/party/party-dialog.html',
                    controller: 'PartyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                email: null,
                                firstName: null,
                                lastName: null,
                                cellNumber: null,
                                signature: null,
                                signatureContentType: null,
                                initial: null,
                                initialContentType: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('account.party', null, { reload: 'party' });
                }, function() {
                    $state.go('account.party');
                });
            }]
        })
        .state('account.party.edit', {
            parent: 'account.party',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/party/party-dialog.html',
                    controller: 'PartyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Party', function(Party) {
                            return Party.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('account.party', null, { reload: 'party' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('account.party.delete', {
            parent: 'account.party',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/party/party-delete-dialog.html',
                    controller: 'PartyDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Party', function(Party) {
                            return Party.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('account.party', null, { reload: 'party' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('my-party', {
            parent: 'account',
            url: '/my-party',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'jmrsignApp.party.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/party/party-detail.html',
                    controller: 'PartyDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('party');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AppPartySvc','JmrAuth', function($stateParams, AppPartySvc, JmrAuth) {

                    // TODO
                    //console.log('Current Party:' + JmrAuth.currentPartyId());
                    return AppPartySvc.get().$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'party',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('my-party.new', {
            parent: 'inbox',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal','JmrAuth', function($stateParams, $state, $uibModal, JmrAuth) {
                $uibModal.open({
                    templateUrl: 'app/account/party/party-dialog.html',
                    controller: 'PartyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('party');
                            return $translate.refresh();
                        }],

                        entity: function () {
                            return {
                                email: null,
                                firstName: null,
                                lastName: null,
                                cellNumber: null,
                                signature: null,
                                signatureContentType: null,
                                initial: null,
                                initialContentType: null,
                                id: null,
                                allowUserUpdate: true,
                                status: 'ENABLED',
                                type:'EXTERNAL_USER'
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('inbox', null, { reload: true });
                }, function() {
                    $state.go('inbox');
                });
            }]
        })
        .state('my-party.edit', {
            parent: 'my-party',
            url: '/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'JmrAuth', function($stateParams, $state, $uibModal, JmrAuth) {
                $uibModal.open({
                    templateUrl: 'app/account/party/party-dialog.html',
                    controller: 'PartyDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AppPartySvc', function(AppPartySvc) {
                            // TODO
                            return AppPartySvc.get().$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('my-party', null, { reload: 'party' });
                }, function() {
                    $state.go('my-party');
                });
            }]
        });
    }

})();
