(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .controller('InboxController', InboxController);

    InboxController.$inject = [
    '$scope', '$rootScope', 'Auth', 'Principal', 'party',
    'JmrAuth', 'LoginService', 'SignSvc', '$state'];

    function InboxController (
        $scope, $rootScope, Auth, Principal, party,
        JmrAuth, LoginService, SignSvc, $state) {
        var vm = this;

        vm.tabs = [
			{    
                title:'Inbox', 
                icon:'glyphicon-inbox', 
                items: vm.signItems,
                filter: { status: 'NOTIFIED' } },
			{ 
                title:'In Progress', 
                icon:'glyphicon-random', 
                items: vm.progItems,
                filter: { document : {status: 'IN_PROGRESS'}, type: 'EDITOR' } }
		];

        vm.account = null;
        console.log('ITEM');
        console.log(party.id);
        vm.party = party;
        vm.roleRank = rankRole(party.type, party.status);
        console.log('RANK:' + vm.roleRank + hasAccount() + party.status);
        vm.isAuthenticated = null;
        vm.hasAccount = hasAccount;
        vm.hasCurrentAccount = hasAccount;
        vm.login = LoginService.open;
        vm.logout = logout;
        vm.inboxItems = null;
        vm.searchText = '';
        vm.All = '';
        vm.minRole = minRole;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        function hasAccount() {
            return party.id != null
            && vm.party.status != 'DISABLED'
            && vm.party.status != 'RESTRICTED';
        };

        function minRole(type){
            return vm.roleRank >= rankRole(type);
        };

        function rankRole(type, status){

            if(status != null &&
                (status == 'RESTRICTED' ||
                status == 'DISABLED'))
            {
                return 0;
            }

            switch(type) {
                case "EXTERNAL_USER":
                    return 10;
                    break;
                case "INTERNAL_USER":
                    return 20;
                    break;
                case "ACCOUNT_USER":
                    return 30;
                    break;
                case "ADMIN":
                    return 99;
                    break;
                default:
                    return 0;
            }
        };


        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                
                vm.account = account;
                //vm.isAuthenticated = Principal.isAuthenticated;
                //vm.hasAccount = JmrAuth.hasAccount('JMR');
                SignSvc.query(function(result) {
                    vm.inboxItems = result;
                    console.log(result);
                });
            });

        }

        function logout() {
            Auth.logout();
            $state.go('home');
        }

        var unsubscribe = $rootScope.$on('jmrsignApp:documentUpload', function(event, result) {
            vm.wi = result;
            $state.go('documents.setup', {id: vm.wi.documentId, wiid: vm.wi.id  }).then( function() { console.log($state.current) });
            
        });
        //$scope.$on('$destroy', unsubscribe);

    }
})();
