// JSS-54
(function() {
    'use strict';

    angular
        .module('jmrsignApp')
        .factory('DocumentWorkitemsSvc', DocumentWorkitemsSvc);

    DocumentWorkitemsSvc.$inject = ['JmrAuth', '$resource', '$http', '$timeout'];

    function DocumentWorkitemsSvc (JmrAuth, $resource, $http, $timeout) {
        return {
            getWorkItems: function(documentId) {
                var accountId = JmrAuth.currentAccountId();
                var workItems = getWorkItems(documentId, accountId);

                return workItems;
            }
        }

        function getWorkItems(documentId, accountId) {
            var resourceUrl =  'api/accounts/' + accountId + '/documents/' + documentId + '/workitems';
            return $http.get(resourceUrl);
        }
    }
})();
